import { LandingPageBePartOfLZero } from "./be_part_of_lzero/be_part_of_lzero.tsx";
import { LandingPageFooter } from "./footer/footer.tsx";
import { LandingPageHero } from "./hero/hero.tsx";
import { LandingPageJoinCommunity } from "./join_community/join_community.tsx";
import { Analytics } from "@vercel/analytics/react"

export function LandingPage() {
  return (
    <>
      <LandingPageHero></LandingPageHero>
      <LandingPageBePartOfLZero></LandingPageBePartOfLZero>
      <LandingPageJoinCommunity></LandingPageJoinCommunity>
      <LandingPageFooter></LandingPageFooter>
      <Analytics />
    </>
  );
}
